.game-span {
    position: center;
    bottom: 6px;
    left: 10px;
    margin-left: 10px;
    margin-bottom: auto;
    color: white;
    text-align: center;
}


.game-list-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.rocket-league-select {
    background-image: url("https://i.imgur.com/rJDXsLj.png");
    width: 15%;
}

.logo {
}

.logo-button {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
}

.logo-button:hover {
    background-color: #313239;
}

.game-icon {
    width: 30px;
}

.game-icon-big {
    width: 256px;
}


.game-icon-span {
    position: relative;
    bottom: 6px;
    left: 10px;
    padding-right: 10px;
    color: white;
}


.dropdown {
    float: left;
    overflow: hidden;
}

.dropdown .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    min-width: 210px;
}

.navbar a:hover, .dropdown:hover .dropbtn {
    background-color: #313239;
}

.header-div {
    display: flex;
    justify-content: space-between;
    background-color: var(--ion-color-dark-medium);
}

.dropdown-content {
    position: absolute;
    display: block;
    background-color: var(--ion-color-dark-medium);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    #margin-left: 0.25rem;
}

.dropdown-content a {
    float: none;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.second-header-row {
    position: absolute;
    display: flex;
}

.dropdown-content a:hover {
    background-color: #313239;
}

.dropdown:hover .dropdown-content {
    display: block;
}


.search-results {
    position: absolute;
    float: right;
    background-color: var(--ion-color-dark-medium);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
    margin-left: 0.5rem;
    width: 17.5rem;
    display: block;
}
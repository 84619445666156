p {
    color: gray;
}

h1 {
    color: white;
}

h2 {
    color: white;
}


ul {
    color: gray;
}

a {
    color: gray;
}
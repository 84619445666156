.small-spacer {
     margin-right: 10px;
 }

.no-underline-link {
    text-decoration: none;
}

.underline-hover:hover {
    text-decoration: underline;
}


.hidden {
    width: 0;
    height: 0;
    visibility: hidden;
}

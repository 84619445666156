.tournament-bracket-header {
    padding-top: 1rem;
    background-repeat: round;
    width: 290px;
    color: var(--ion-color-darkblue-contrast);
}

.tournament-bracket-header-container {
    display: flex;
}

.team-item-bracket-headline-container {
    padding-left: 0;
    flex-direction: column;
    justify-content: space-around;
}


.tournament-bracket-header-container .team-item-bracket-headline:last-child {
    #width: calc(17rem);
    margin-right: 0px;
}

.team-item-bracket-headline {
    #padding-right: 25px;
    #display: block;
    justify-content: center;
    vertical-align: middle;
    line-height: 1;
    font-size: 10px;
    text-align: center;
    width: calc(18rem);
    margin-right: 50px;
    height: 2.75rem;
    text-color: white;
    background-color:  var(--ion-color-darkblue);
}

.team-item-bracket-headline-text {
    font-size: 1rem;
}

.best-of-text {
    color: var(--ion-color-dark-lighter-tint);
    font-size: 0.7rem;
    margin: 3px;
}

.detail-bracket-header-button {
    padding: 0;
}

.dropdown-div-bracket-detail {
    padding: 0;
    top: -60%;
    float: right;
}

.bracket-detail-dropdown  {
    display: grid;
}

.bracket-headline-title {
    margin-top: 0.2rem;
}

.bracket-headline-title-no-best-of {
    margin-top: 0.6rem;
}

ion-content {
    --ion-background-color: var(--ion-color-dark);
}

.match-team-name {
    text-align: center;
    color: white;
}

.list-md {
    padding-top: 0;
}

.vs-span {
    color: white;
    position: relative;
    bottom: 20px;
}

.vs-div {
    position: relative;
    padding: 10px 0px;
    background-color: var(--ion-color-darkblue);
    display: flex;
}

.match-header-team {
    position: relative;
    width: 40%;
}

.match-header-vs {
    position: relative;
    font-size: 20px;
    flex: 1;
    text-align: center;
    width: 20%;
}

.match-header-vs-score {
    font-size: 40px;
    font-weight: 500;
    color: var(--ion-color-dark-light);
}

.match-header-vs-note {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    color: white;
}

.match-header-vs-note-date {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    color: gray
}

.team-match-div {
    width: 40%;

}

.font-bigger {
    font-size: 200%;
}

.divider {
    vertical-align: 2px;
    margin: 0 -2px;
    color: var(--ion-color-darkblue-secondary);
}
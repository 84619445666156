.tournament-item-div {
    display: grid;
}

.tournament-name-div {
    text-decoration: none;
    color: white;
}

.tournament-item {
    #height: 5rem;
 }

.tournament-date {
    color: gray;
    margin-right: 10px;
}

.tournament-name {
    margin-left: 0px !important;
    margin: 10px;
}

.color-contrast {
    color: var(--ion-color-darkblue-secondary-contrast)
}

.seperator {
    background-color: var(--ion-color-dark-medium);
    height: 1px;
    display: flex;
    align-items: center;
    width: 90%;
    padding-left: 5rem;
}

.middle {
    height: 1px;
    display: flex;
    align-items: center;
}


/* The container <div> - needed to position the dropdown content */
.dropdown-div-match-detail {
    position: relative;
    display: inline-block;
    width: 5%;
}

/* Dropdown Content (Hidden by Default) */
.match-detail-dropdown {
    float: none;
    color: white;
    #padding: 12px 16px;
    text-decoration: none;
    #display: block;
    text-align: left;
    position: absolute;
    #background-color: var(--ion-color-dark-light);
    #min-width: 160px;
    #box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}


/* Show the dropdown menu on hover */
.dropdown-div-match-detail:hover .match-detail-dropdown {display: block;}



.detail-button {
    margin-left: auto;
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    color: var(--ion-color-darkblue-secondary-contrast);
    font-size: 1rem;
}

.participant {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 0.75rem 0.75rem 0.75rem 0.1rem;
}

.name-entry {
    float: left;
    margin-right: auto;
}

.score-entry {
    margin-left: auto;
}

.match-date {
    color: var(--ion-color-dark-lighter-tint);
    font-size: 0.75rem;
}

.name-label {
    margin-left: 0.75rem;
    white-space: nowrap;
    word-wrap: break-word;
}

.name-inline-block {
    display: inline-block;
}

.name-block {
    display: block;
}

.live-tag {
    color: #6441A4;
}
html {
    height: 100%;
    width: 100%;
}

body {
    font-family: sans-serif;
    margin: 0;
    height: 100%;
}

.tournament-brackets {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    list-style-type: none;
    #background: var(--ion-color-darkblue-secondary);
    margin-bottom: 50px;
}

.bracket {
    padding-left: 0;
    display: -webkit-box;
    display: flex;
    margin: 0;
    padding: 30px 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    justify-content: space-around;
    list-style-type: none;
    border-right: 1px dashed var(--ion-color-dark-lighter);
    flex: 1;
}

.team-item {
    background-color:  var(--ion-color-darkblue-secondary);
    padding: .5rem;
    display: block;
    #margin: .5rem 10px;
    position: relative;
    vertical-align: middle;
    line-height: 2;
    text-align: center;
    width: 18rem
}

.team-item-bracket-container {
    display: flex;
    justify-content: center;
}



.team-item-placeholder {
    padding: .5rem;
    display: block;
    position: relative;
    vertical-align: middle;
    line-height: 2;
    text-align: center;
    width: 18rem
}

.team-item-placeholder:before {
    position: absolute;
    content: '';
    width: 340px;
    height: 2px;
    left: 275px;
    top: 50%;
    background-color: #4f7a38;
    transform: translateX(-100%);
}

.team-item-placeholder:after {
    position: absolute;
    content: '';
    width: 25px;
    height: 2px;
    left: 300px;
    top: 50%;
    background-color: #4f7a38;
    transform: translateX(-100%);
}

.team-item time {
    display: inline-block;
    background-color: #dbdbdb;
    font-size: .8rem;
    padding: 0 .6rem;
}

.wrapper {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-right: 10rem;
}

.item {
    display: flex;
    flex-direction: row-reverse;
}

.stage-div {
    display: flex;
    justify-content: center;
    #flex-direction: row-reverse;
}

.item-parent {
    position: relative;
    margin-left: 50px;
    display: flex;
    align-items: center;
}

.item-parent:after {
    position: absolute;
    content: '';
    width: 25px;
    height: 2px;
    left: 0;
    top: 50%;
    background-color:  #4f7a38;
    transform: translateX(-100%);
}

.item-parent-straight:after {
    position: absolute;
    content: '';
    width: 50px;
    height: 2px;
    left: 0;
    top: 50%;
    background-color: #4f7a38;
    transform: translateX(-100%);
}

.item-childrens {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.item-child {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
}

.item-child:before {
    content: '';
    position: absolute;
    background-color:  #4f7a38;
    right: 0;
    top: 50%;
    transform: translateX(100%);
    width: 25px;
    height: 2px;
}

.item-child:after {
    content: '';
    position: absolute;
    background-color:  #4f7a38;
    right: -25px;
    height: calc(50% + 35px);
    width: 2px;
    top: 50%;
}

.item-child:last-child:after {
    transform: translateY(-100%);
}

.item-child:only-child:after {
    display: none;
}
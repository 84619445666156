.no-padding {
    padding: 0px;
}

.tournament-page-header {
    #background-image: url(https://cdn.escharts.com/uploads/public/59c/418/00a/59c41800a121f781513542.jpg);
    #background-color: var(--ion-color-dark-medium-shade);
    padding-top: 1rem;
    background-repeat: round;
    width: 100%;
    color: var(--ion-color-darkblue-contrast);
}

.tournament-headers {
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    justify-content: space-around;
    #border-bottom: 1px solid var(--ion-color-darkblue-shade);
}

.tournament-header-label {
    width: 100%;
    #color: var(--ion-color-darkblue-contrast);
    #text-align: center;
    #font-weight: 400;
    border-right: 1px solid var(--ion-color-dark-lighter);
    #margin: 0;
    #background: var(--ion-color-darkblue);
}

.tournament-page-header-container {
    #padding-left: 2rem;
    padding-bottom: 2rem;
}

.tournament-page-name {
    font-size: large;
    font-weight: bolder;
}

.tournament-page-info-headline {
    color: #b3b4b6;
}

.header-info-item {
    border-right: 1px solid rgba(255, 255, 255, .15);
    padding-left: 1rem;
    padding-right: 1rem;
}

.tournament-page-header-container .header-info-item:first-child {
    padding-left: 0rem;
}

.tournament-team-div {
    padding: 2rem;
    margin: auto;
    #width: 50%;
}

.team-list-div {
    float: left;
    text-align: center;
    max-width: 12.5rem;
    max-height: 6.5rem;
    padding: .5rem;
}


.group-match-col {
    min-width: 500px;
}

.scroll {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.scroll-inner {
    display: inline-block;
}

@-moz-document url-prefix() {
    /*We only need that for firefox since chromes does actually fit the handy scroll correctly above the footer while firefox does not*/
    .scroll .handy-scroll {
        bottom: 56px;
        z-index: 1000;
    }
}


.scroll .handy-scroll {
    z-index: 1000;
}
.vodPlayer {
    transition: 0.5s height;
    width: 100%;
    height: 100%;
    background-color: #2f314a;
}

.miniPlayer {
    border: 0px none;
    #height: 225px;
    position: fixed;
    width: 400px;
    overflow: hidden;
    bottom: 45px;
    right: 0px;
}


.miniPlayerInner {
    height: 225px;
    border: 0px none;
    #width: 165px;
    margin-bottom: 0px;
    margin-left: auto;
}


.footerToolbar {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    transition: 0.5s height;
}

.footerToolbarNo {
    width: 100%;
    height: 100%;
    visibility: hidden;
}

.footer-text-div {
    height: 2rem;
    text-align: center;
    display: flex;
    #align-items: end;
    margin-right: 2rem;
    margin-top: 1rem;
}

.footer-text {
    margin-right: 1rem;
    color: gray;
    font-size: 0.85rem;
}

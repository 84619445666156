.winner {
    color: #4f7a38 !important;
}

.loser {
    color: #9d251c !important;;
}

.winner-name {

}

.loser-name {
    color: #605e65 !important;;
}

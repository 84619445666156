.score-label {
    margin-left: 2rem;
    font-size: 3rem;

}

.date-label {
    margin-right: 0rem;
    float: right;
}

.match-buttons{
    display: flex;
    justify-content: center;
}

.matchDiv {
    display: flex;
    justify-content: left;
    width: 100%;
}

.matchDiv2 {
    width: 40%;
}

.match-item-vs-div {
    position: relative;
    padding: 0px;
    width: 100%;
    #background-color: var(--ion-color-darkblue);
    display: flex;
}

/* Set width so the team boxes all align*/
.match-item-header-team {
    position: relative;
    width: 25%;
}


/*Center images above the team names*/
.match-item-header-team img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.match-header-vs {
    position: relative;
    font-size: 20px;
    flex: 1;
    text-align: center;
    width: 20%;
}

.match-header-vs-score {
    font-size: 40px;
    font-weight: 500;
    color: var(--ion-color-dark-light);
}

.match-header-vs-note {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    color: white;
}

.match-header-vs-note-date {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    color: gray
}

.match-item-divider {
    vertical-align: 2px;
    margin: 0 -2px;
    color: var(--ion-color-darkblue);
}


.float-left {
    float: left;
}

.float-right {
    float: right;
}

.border {
    border-right: solid var(--ion-color-dark);
}

.div-float-left {
    float: left;
    width: 50%;
}

.div-float-right {
    float: right;
    width: 50%;
}